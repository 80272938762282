import { Box, Button, IconButton, Typography } from "@mui/material";
import { FastField } from "formik";
import React from "react";
import { BaseSelect, BaseTextField } from "../../../../base";
import { Add, DeleteOutline } from "@mui/icons-material";
import { cloneDeep } from "lodash";
import { frequencyUnits } from "../../../constants/frequencyUnits";

const ReminderFrequency = ({ fieldName, values, setFieldValue }) => {
  const handleAnswerAdd = () => {
    let answerChoice = values[fieldName]
      ? [...cloneDeep(values[fieldName]), { value: null, unit: null }]
      : [{ value: null, unit: null }];
    setFieldValue(`${fieldName}`, answerChoice);
  };

  const handleAnswerDelete = (i) => {
    let val = cloneDeep(values[fieldName]);
    let answerChoice = val?.filter((_, index) => index !== i);
    setFieldValue(`${fieldName}`, answerChoice);
  };

  let getRank = (val) => {
    if (val === 1) return "1st";
    if (val === 2) return "2nd";
    if (val === 3) return "3rd";
    return `${val}th`;
  };

  return (
    <Box display="grid" placeItems="center">
      {values[fieldName] &&
        values[fieldName]?.length &&
        values[fieldName].map((freq, ind) => (
          <Box display="flex" alignItems="center" my={1} position="relative">
            <Box display="flex" alignItems="center" gap={1}>
              <FastField
                component={BaseTextField}
                type="number"
                label={`Frequency ${ind + 1}`}
                name={`${fieldName}.${ind}.value`}
              />
              <FastField
                component={BaseSelect}
                name={`${fieldName}.${ind}.unit`}
                label="Unit"
                optionLabel="unitName"
                optionValue="unitVal"
                options={frequencyUnits}
              />
            </Box>
            <Box mt={1.5} display={ind ? "block" : "none"}>
              <IconButton
                onClick={() => handleAnswerDelete(ind)}
                edge="end"
                color="error"
              >
                <DeleteOutline />
              </IconButton>
            </Box>
            {values[fieldName][ind].value && values[fieldName][ind].unit ? (
              <Box
                fontSize="12px"
                position="absolute"
                right="-85%"
                top="25px"
                width="80%"
                ml={1}
              >
                {getRank(ind + 1)} reminder - after{" "}
                {values[fieldName][ind].value}{" "}
                {
                  frequencyUnits?.find(
                    (curr) => curr?.unitVal === values[fieldName][ind].unit
                  )?.unitName
                }{" "}
                from date of candidate{" "}
                {values?.emailCategoryId === 10
                  ? "account activation"
                  : "account registration"}
              </Box>
            ) : null}
          </Box>
        ))}
      <Typography fontSize="12px" color="error" mb={1}>
        * Saturday & Sunday are excluded
      </Typography>

      <Button
        variant="contained"
        onClick={handleAnswerAdd}
        sx={{ width: 20, height: 30 }}
      >
        <Add />
      </Button>
    </Box>
  );
};

export default ReminderFrequency;
