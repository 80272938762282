export const newProfessional = () => {
  return {
    candidatesProfessionalQualificationsId: null,
    professionalQualificationTitle: "",
    qualifyingInstituteBodyName: "",
    statusProfessionalQualificationId: null,
    membershipQualificationNumber: "",
    fromDate: "",
    toDate: "",
    dateOfAccreditation: "",    
    streetAddressLine1: "",
    streetAddressLine2: "",
    cityTownDistrict: "",
    stateProvince: "",
    zipPostalAreaCode: "",
    countryId: null,
    institutePhoneCountryCode: "",
    institutePhoneNumber: "",
    additionalComments: "",
    attachments: [],
    qualificationTypeId: 1,
    isExpanded:true,
    otherProffesionalQualificationStatus:'',
    selectedTab:true
  };
};
