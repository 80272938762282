export const newFamilyDetails = () => {
  return {
    fathersFirstName: "",
    fathersMiddleName: "",
    fathersLastName: "",
    mothersFirstName: "",
    mothersMiddleName: "",
    mothersLastName: "",
    spouseFirstName: "",
    spouseMiddleName: "",
    spouseLastName: "",
    mothersMaidenNameExists: false,
    mothersMaidenName: "",
  };
};
