import React, { useEffect, useMemo } from "react";
import { getAdditionalEmailNotificationGroups } from "../../../../../store/actions/systemAdminActions";
import { getCurrentFileNameAndFunction } from "../../../../../utils/getCurrentFileNameAndFunction";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Box, Chip } from "@mui/material";
import CustomTooltip from "../../../../common/CustomTooltip";

const DisplaySelectedNotificationGroups = ({
  notificationGroupIds,
  emailCategoryId,
  render,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    let logDetails = getCurrentFileNameAndFunction(
      import.meta.url,
      "useEffect"
    );
    dispatch(
      getAdditionalEmailNotificationGroups(emailCategoryId, "table", logDetails)
    );
  }, [emailCategoryId, render]);

  const { additionalEmailNotificationGroupForTable } = useSelector(
    (state) => state.systemAdmin
  );

  let selectedGroups = useMemo(() => {
    return Object.keys(additionalEmailNotificationGroupForTable)?.length
      ? (
          additionalEmailNotificationGroupForTable[emailCategoryId] ?? []
        )?.filter((curr) => notificationGroupIds?.find((id) => id === curr?.id))
      : [];
  }, [notificationGroupIds, additionalEmailNotificationGroupForTable]);

  return (
    <Box display="flex" flexWrap="wrap" justifyContent="center" gap={1}>
      {selectedGroups?.map((group) => (
        <CustomTooltip title={group?.groupDescription}>
          <Chip
            label={group?.groupName}
            sx={{
              color: "#fff",
              backgroundColor: (theme) => theme.palette.primary.main,
              cursor: "pointer",
            }}
          />
        </CustomTooltip>
      ))}
    </Box>
  );
};

export default DisplaySelectedNotificationGroups;
