// import { maxBy } from "lodash";
// import { majorLanguagesRanges } from "./../utils/majorLanguagesRanges";
// import { Text } from "@react-pdf/renderer";

// export const TextParsedAfterDetectingLanguage = ({ style = [], children }) => {
//   //default font family
//   let fontFamily = "Montserrat";

//   //checks if children is a date
//   const containsDate = (str) => {
//     // Define a regex pattern for the DD/MM/YYYY format
//     const datePattern = /\b\d{2}\/\d{2}\/\d{4}\b/;

//     // Test the string against the pattern
//     return datePattern.test(str);
//   };

//   // will check for language only when children is string and it is not a date
//   if (children && !Array.isArray(children) && !containsDate(children)) {
//     console.log('00000',children)
//     const [script] = maxBy(
//       Object.entries(majorLanguagesRanges),
//       ([_, test]) => {
//         const match = children.match(test);
//         return match ? match.length : 0;
//       }
//     );

//     //for simple english i.e script === 'Latin' we will use our default font family i.e Montserrat
//     fontFamily = script === "Latin" ? fontFamily : `Noto Sans ${script}`;
//   }

//   //if style already is an array , we will simply initialize finalStyles with it
//   //otherwise , it would be an object and we can simply push it into an empty array
//   let finalStyles = Array.isArray(style) ? style : [style];

//   //finally , we will push our font family
//   finalStyles.push({ fontFamily });

//   return <Text style={finalStyles}>{children}</Text>;
// };

//----------------------------------------------------------------------------------------------------//
//below logic breaks the text into characters and apply & detect font according to it to resolve multiple langauges present in one text
import { maxBy } from "lodash";
import { majorLanguagesRanges } from "./../utils/majorLanguagesRanges";
import { Text } from "@react-pdf/renderer";

export const TextParsedAfterDetectingLanguage = ({ style = [], children }) => {
  const detectScript = (char) => {
    const [script] = maxBy(
      Object.entries(majorLanguagesRanges),
      ([_, test]) => {
        const match = char.match(test);
        return match ? match.length : 0;
      }
    );
    return script || "Latin";
  };

  //checks if children is a date
  const containsDate = (str) => {
    // Define regex patterns for various date formats
    const datePatterns = [
      /\b\d{2}\/\d{2}\/\d{4}\b/, // DD/MM/YYYY
      /\b\d{2}\s\w{3}\s\d{4}\b/, // DD MMM YYYY (e.g., 14 Aug 2024)
    ];

    // Check against all patterns
    return datePatterns.some((pattern) => pattern.test(str));
  };

  //replaces full-width characters with normal characters
  const replaceFullWidthChars = (text) => {
    return text.replace(/[\uFF01-\uFF5E]/g, (char) => {
      return `${String.fromCharCode(char.charCodeAt(0) - 0xfee0)} `;
    });
  };

  const splitTextByScript = (text) => {
    const parts = [];
    let currentScript = null;
    let currentPart = "";

    for (let char of text) {
      char = replaceFullWidthChars(char);
      const script = detectScript(char);

      if (script !== currentScript) {
        if (currentPart)
          parts.push({ text: currentPart, script: currentScript });
        currentScript = script;
        currentPart = char;
      } else {
        currentPart += char;
      }
    }

    if (currentPart) parts.push({ text: currentPart, script: currentScript });

    return parts;
  };

  const mergeTextForArabicScript = (textParts) => {
    const mergedTextParts = [];
    let currentText = "";

    textParts.forEach((part) => {
      if (part.script === "Arabic" || part.text === " ") {
        currentText += part.text;
      } else {
        if (currentText && part.script !== "Arabic" && part.text !== " ") {
          mergedTextParts.push({ text: currentText, script: "Arabic" });
          currentText = "";
        }
        mergedTextParts.push({ text: part.text, script: part.script });
      }
    });

    if (currentText) {
      mergedTextParts.push({ text: currentText, script: "Arabic" });
    }

    return mergedTextParts;
  };

  let finalText = [];

  // Handle the case when children is a string and not a date
  if (children && !Array.isArray(children) && !containsDate(children)) {
    let textParts = splitTextByScript(children);
    textParts = mergeTextForArabicScript(textParts);

    textParts.map((part, index) => {
      const fontFamily =
        part.script === "Latin" || !part.script
          ? "Montserrat"
          : `Noto Sans ${part.script}`;

      finalText.push(
        <Text key={index} style={{ fontFamily }}>
          {part.text}
        </Text>
      );
    });
  }

  //if style already is an array , we will simply initialize finalStyles with it
  //otherwise , it would be an object and we can simply push it into an empty array
  let finalStyles = Array.isArray(style) ? style : [style];
  finalStyles.push({ fontFamily: "Montserrat" });

  return (
    <Text style={finalStyles}>{finalText?.length ? finalText : children}</Text>
  );
};
