import { ForwardToInbox, PersonAdd, CorporateFare } from "@mui/icons-material";
import CommonSystemAdminLayout from "./CommonSystemAdminLayout";

const navItems = [
  {
    name: "Add Internal Users",
    url: "/system-admin/system-settings/add-internal-users",
    icon: <PersonAdd />,
  },
  {
    name: "Email Provider Setting",
    url: "/system-admin/system-settings/email-provider-settings",
    icon: <ForwardToInbox />,
  },
  {
    name: "Approve Organization",
    url: "/system-admin/system-settings/update-organization-status",
    icon: <CorporateFare />,
  },
];

export const SystemSettingsLayout = () => (
  <CommonSystemAdminLayout navItems={navItems} layout="system" />
);
