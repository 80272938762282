import { Button, Grid, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AddAdditionalEmailSettingsModal from "./components/AddAdditionalEmailSettingsModal";
import { useRef, useState } from "react";
import SystemAdminTable from "../common/SystemAdminTable";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteClientSettingsModal from "./components/DeleteClientSettingsModal";
import { getTimestampFromString } from "../../../utils/DateTimeHelper";
import {
  getAdditionalEmailDetails,
  deleteAdditionalEmailDetails,
  getAdditionalEmailTypes,
} from "../../../store/actions/systemAdminActions";
import { GET_ADDITIONAL_EMAIL_SETTINGS_DATA } from "../../../store/actions/actionTypes";
import { convertFrequencyArrayIntoObject } from "../utils/convertFrequencyArrayIntoObject";
import { frequencyUnits } from "../constants/frequencyUnits";
import { getCurrentFileNameAndFunction } from "../../../utils/getCurrentFileNameAndFunction";
import { Field, Form, Formik } from "formik";
import { BaseSelect } from "../../base";
import CircularLoader from "../../../common/CircularLoader";
import DisplaySelectedNotificationGroups from "./components/innerComponents/DisplaySelectedNotificationGroups";

const AdditionalEmailSettings = () => {
  const [modal, setmodal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [editDetailsFlag, setEditDetailsFlag] = useState(false);
  const [render, setRender] = useState(false);
  const dispatch = useDispatch();
  const formRef = useRef();

  const {
    selectedOrg,
    additionalEmailSettingsData,
    loading,
    additionalEmailTypesData,
    customEmailCategoriesLists,
  } = useSelector((state) => state.systemAdmin);

  useEffect(() => {
    let logDetails = getCurrentFileNameAndFunction(
      import.meta.url,
      "useEffect"
    );

    dispatch(getAdditionalEmailDetails(selectedOrg, 1, logDetails));
    formRef.current.setFieldValue("emailType", 1);

    if (!additionalEmailTypesData) {
      dispatch(getAdditionalEmailTypes(logDetails));
    }

    return () =>
      dispatch({ type: GET_ADDITIONAL_EMAIL_SETTINGS_DATA, payload: null });
  }, [selectedOrg]);

  const columnActionArray = (params) => {
    let tempActionArray = [];

    tempActionArray.push(
      <GridActionsCellItem
        icon={<EditIcon color="orange" />}
        label="Edit"
        onClick={() => {
          setmodal(true);
          setSelectedRowId(params.id);
          setEditDetailsFlag(true);
        }}
      />
    );

    tempActionArray.push(
      <GridActionsCellItem
        icon={<DeleteIcon color="error" />}
        label="delete"
        onClick={() => {
          setDeleteModal(true);
          setSelectedRowId(params.id);
        }}
      />
    );

    return tempActionArray;
  };

  const columns = [
    {
      field: "categoryName",
      headerName: "Email Category",
      flex: 1,
      minWidth: 350,
    },
    {
      field:
        formRef.current?.values?.emailType === 1
          ? "frequency"
          : "notificationGroupIds",
      headerName:
        formRef.current?.values?.emailType === 1
          ? "Email Frequency"
          : "Notification Groups",
      flex: 1,
      minWidth: formRef.current?.values?.emailType === 1 ? 300 : 350,
      renderCell: (params) => {
        if (formRef.current?.values?.emailType === 1) {
          let freqArr = params.row?.frequency
            ? convertFrequencyArrayIntoObject(params.row?.frequency)
            : [];

          const resultString = freqArr
            .map((item) => {
              const unit = frequencyUnits.find((u) => u.unitVal === item.unit);
              return `${item.value} ${unit?.unitName}`;
            })
            .join(", ");

          return resultString;
        } else {
          return (
            <DisplaySelectedNotificationGroups
              key={params.row?.id}
              notificationGroupIds={params.row?.notificationGroupIds}
              emailCategoryId={params.row?.emailCategoryId}
              render={render}
            />
          );
        }
      },
    },
    {
      field: "createdBy",
      headerName: "Created By",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Created On",
      flex: 1,
      type: "date",
      valueFormatter: ({ value }) => getTimestampFromString(value),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => columnActionArray(params),
    },
  ];

  const handleDeleteCustomField = (emailTypeId) => {
    let logDetails = getCurrentFileNameAndFunction(
      import.meta.url,
      "handleDeleteCustomField"
    );

    dispatch(
      deleteAdditionalEmailDetails(
        selectedRowId,
        selectedOrg,
        emailTypeId,
        logDetails
      )
    );
  };

  const handleGroupSelect = (emailType) => {
    let logDetails = getCurrentFileNameAndFunction(
      import.meta.url,
      "handleGroupSelect"
    );

    setRender(!render);

    dispatch(getAdditionalEmailDetails(selectedOrg, emailType, logDetails));
  };

  return (
    <Box sx={{ height: "100%", overflowY: "auto" }} pr={1}>
      <Formik
        enableReinitialize
        initialValues={{ emailType: 1 }}
        innerRef={formRef}
      >
        {(form) => (
          <Form style={{ height: "100%" }}>
            <Grid
              container
              justifyContent="center"
              sx={{ marginBottom: "1em" }}
              position="relative"
            >
              <Grid item xs={12} md={6}>
                {additionalEmailTypesData ? (
                  <Field
                    component={BaseSelect}
                    name="emailType"
                    label="Select group type"
                    optionLabel="emailType"
                    optionValue="id"
                    options={additionalEmailTypesData}
                    runWhenSelect={(emailType) => handleGroupSelect(emailType)}
                  />
                ) : (
                  <CircularLoader size={25} height="10vh" />
                )}
              </Grid>
              <Grid item xs={12} mt={3} position="absolute" right={0}>
                <Button
                  variant="contained"
                  size="small"
                  disableElevation
                  startIcon={<AddIcon />}
                  onClick={() => setmodal(true)}
                >
                  Add New Email Settings
                </Button>
              </Grid>
            </Grid>

            <SystemAdminTable
              columns={columns}
              rows={additionalEmailSettingsData || []}
              loading={loading || additionalEmailSettingsData === null}
            />

            {modal && (
              <AddAdditionalEmailSettingsModal
                open={modal}
                handleClose={() => {
                  setmodal(false);
                  setEditDetailsFlag(false);
                }}
                editDetailsFlag={editDetailsFlag}
                selectedEmailSettings={additionalEmailSettingsData?.find(
                  (curr) => curr?.id === selectedRowId
                )}
                emailType={form.values?.emailType}
              />
            )}

            {deleteModal && (
              <DeleteClientSettingsModal
                deleteText="Email settings will be permanently deleted!"
                open={deleteModal}
                handleClose={() => setDeleteModal(false)}
                handleDelete={() =>
                  handleDeleteCustomField(form.values?.emailType)
                }
              />
            )}
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default AdditionalEmailSettings;
