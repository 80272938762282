import {
  DriveFileRenameOutline,
  Email,
  PostAdd,
  Tune,
  CurrencyExchange,
  Public,
  Diversity2,
  SettingsSuggest,
  PlaylistAddCircle,
  RemoveModerator,
} from "@mui/icons-material";
import CommonSystemAdminLayout from "./CommonSystemAdminLayout";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { getCurrentFileNameAndFunction } from "../../../utils/getCurrentFileNameAndFunction";
import {
  getCustomFieldCategoriesLists,
  getCustomFieldTypesLists,
  getOrgsLists,
  setSelectedOrg,
} from "../../../store/actions/systemAdminActions";
import { useDispatch } from "react-redux";
import { GET_ORGS } from "../../../store/actions/actionTypes";
import CircularLoader from "./../../../common/CircularLoader";

const navItems = [
  {
    name: "Custom Fields",
    url: "/system-admin/client-settings/custom-fields",
    icon: <DriveFileRenameOutline />,
  },
  {
    name: "Custom Emails",
    url: "/system-admin/client-settings/custom-emails",
    icon: <Email />,
  },
  {
    name: "Custom Consent Form",
    url: "/system-admin/client-settings/custom-consent-form",
    icon: <PostAdd />,
  },
  {
    name: "Other Settings",
    url: "/system-admin/client-settings/org-settings",
    icon: <Tune />,
  },
  {
    name: "Update Check Prices",
    url: "/system-admin/client-settings/update-check-prices",
    icon: <CurrencyExchange />,
  },
  {
    name: "Research Countries Settings",
    url: "/system-admin/client-settings/research-countries-settings",
    icon: <Public />,
  },
  {
    name: "Update Relationships",
    url: "/system-admin/client-settings/update-relationships",
    icon: <Diversity2 />,
  },
  {
    name: "Additional Email Settings",
    url: "/system-admin/client-settings/additional-email-settings",
    icon: <SettingsSuggest />,
  },
  {
    name: "Additional Check Settings",
    url: "/system-admin/client-settings/additional-check-settings",
    icon: <PlaylistAddCircle />,
  },
  {
    name: "Remove Permissions",
    url: "/system-admin/client-settings/remove-permissions",
    icon: <RemoveModerator />,
  },
];

const ClientSettingsLayout = () => {
  const dispatch = useDispatch();
  const { orgsLists } = useSelector((state) => state.systemAdmin);

  useEffect(() => {
    let logDetails = getCurrentFileNameAndFunction(
      import.meta.url,
      "useEffect"
    );

    dispatch(getOrgsLists(logDetails));
    dispatch(getCustomFieldCategoriesLists(logDetails));
    dispatch(getCustomFieldTypesLists(logDetails));
    return () => {
      dispatch(setSelectedOrg(0));
      dispatch({
        type: GET_ORGS,
        payload: [],
      });
    };
  }, []);

  return (
    <>
      {orgsLists?.length ? (
        <CommonSystemAdminLayout navItems={navItems} />
      ) : (
        <CircularLoader />
      )}
    </>
  );
};

export default ClientSettingsLayout;
