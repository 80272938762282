export const newReference = () => {
  return {   
    fullName: "",
    title: "",
    companyName: "",
    professionalRelationshipId: null,
    city: "",
    countryId: null,
    email: "",
    referencePhoneCountryCode: "",
    referencePhoneNumber: "",
    additionalComments: "",
    isExpanded:true,
    selectedTab:true
  };
};
