import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Popper,
  styled,
  TextField,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loginAsCandidateInOps,
  loginAsHrInOps,
} from "../../../../../../../../store/actions/operationActions";
import { useNavigate } from "react-router-dom";
import { getLoggedInUserHrOrganizationId } from "../../../../../../../../utils/UserHelper";
import { loginAsCandidateInVendor } from "../../../../../../../../store/actions/hrActions";
import { getCurrentFileNameAndFunction } from "../../../../../../../../utils/getCurrentFileNameAndFunction.js";
import CustomTooltip from "../../../../../../../common/CustomTooltip.jsx";

const HrModalBox = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 0px 5px;
  min-height: ${({ showDropdownForSelectingUsers }) =>
    showDropdownForSelectingUsers ? "30vh" : "10vh"};
  min-width: ${({ showDropdownForSelectingUsers }) =>
    showDropdownForSelectingUsers ? "45vw" : "40vw"};
  overflow-y: hidden;
`;

const SwitchAccountInOpsModal = ({ open, handleClose, ...props }) => {
  const { OpsBasicCandidateInfo } = useSelector((state) => state.operations);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState(null);

  let { email, hrOrganizationId, hrOrganizations } = useMemo(() => {
    //login as hr from ops settings
    if (props?.selectedCandidate && props?.switchAccountInto === "HR") {
      let hrOrganizations = props?.rows?.find(
        (curr) =>
          curr?.hrOrganizationsId === props?.selectedCandidate?.hrOrganizationId
      )?.hrOrganization;

      return {
        email: "",
        hrOrganizationId: props?.selectedCandidate?.hrOrganizationId,
        hrOrganizations: hrOrganizations,
      };
    } else if (props?.selectedCandidate) {
      //login as candidate from candidates table

      let { candidateEmail, hrOrganizationId } = props?.rows?.find(
        (curr) => curr?.candidatesCasesId === props?.selectedCandidate
      );
      return {
        email: candidateEmail,
        hrOrganizationId: hrOrganizationId || getLoggedInUserHrOrganizationId(),
        hrOrganizations: [],
      };
    } else {
      //login as candidate from basic candidate info
      return {
        email: OpsBasicCandidateInfo?.candidateEmail,
        hrOrganizationId: OpsBasicCandidateInfo?.hrOrganizationId,
        hrOrganizations: [],
      };
    }
  }, [OpsBasicCandidateInfo, props]);

  const handleSubmit = () => {
    let logDetails = getCurrentFileNameAndFunction(
      import.meta.url,
      "handleSubmit"
    );

    //login as hr from ops settings
    if (props?.switchAccountInto === "HR") {
      dispatch(loginAsHrInOps({ email: selectedUser }, navigate, logDetails));
    } else if (props?.switchAccountInto === "Vendor") {
      dispatch(
        loginAsCandidateInVendor(
          {
            email,
            hrOrganizationId,
          },
          navigate,
          logDetails
        )
      );
    } else {
      dispatch(
        loginAsCandidateInOps(
          {
            email,
            hrOrganizationId,
          },
          navigate,
          logDetails
        )
      );
    }
    handleClose();
  };

  return (
    <Grid item xs={12}>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            maxWidth: "none",
            maxHeight: "95vh",
          },
        }}
      >
        <HrModalBox
          showDropdownForSelectingUsers={props?.showDropdownForSelectingUsers}
        >
          <DialogContent sx={{ paddingBottom: 0 }}>
            {props?.showDropdownForSelectingUsers && hrOrganizations?.length ? (
              <Box mb={1}>
                <Autocomplete
                  disablePortal
                  disableClearable
                  id="org-selection"
                  options={hrOrganizations}
                  size="small"
                  sx={{
                    width: { xs: 250, md: "auto" },
                  }}
                  value={selectedUser}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setSelectedUser(newValue?.loginEmail);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select User" />
                  )}
                  filterOptions={(options, { inputValue }) =>
                    inputValue?.length
                      ? options?.filter((option) =>
                          option?.loginEmail
                            .toLowerCase()
                            .includes(inputValue.toLowerCase())
                        )
                      : options
                  }
                  renderOption={(props, item) => {
                    let itemString = `${item.userName || ""} (${
                      item.loginEmail
                    })`;

                    return (
                      <li {...props} key={item.loginEmail}>
                        <Box>
                          {itemString?.length > 100 ? (
                            <CustomTooltip
                              tooltipmaxwidth={400}
                              title={`${itemString} - ${item?.subRoleName}`}
                            >
                              <Box component="span">{`${itemString.slice(
                                0,
                                25
                              )}...`}</Box>
                            </CustomTooltip>
                          ) : (
                            <CustomTooltip
                              tooltipmaxwidth={400}
                              title={item?.subRoleName}
                            >
                              <>{itemString}</>
                            </CustomTooltip>
                          )}
                        </Box>
                      </li>
                    );
                  }}
                />
              </Box>
            ) : null}

            <Alert
              severity="warning"
              sx={{
                display: props?.showDropdownForSelectingUsers
                  ? selectedUser
                    ? "flex"
                    : "none"
                  : "flex",
              }}
            >
              Are you sure you want to login into :{" "}
              <strong>{email || selectedUser}</strong> ?
            </Alert>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: "20px",
            }}
          >
            <Button
              variant="contained"
              color="error"
              mr={1}
              onClick={handleClose}
            >
              Close
            </Button>{" "}
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={
                props?.showDropdownForSelectingUsers
                  ? selectedUser
                    ? false
                    : true
                  : false
              }
            >
              Login
            </Button>
          </DialogActions>
        </HrModalBox>
      </Dialog>
    </Grid>
  );
};

export default SwitchAccountInOpsModal;
