import { Box, Grid } from "@mui/material";
import { Outlet } from "react-router-dom";
import PanelCard from "../../../common/cards/PanelCard";
import ScrollableGrid from "../../../common/ScrollableGrid";
import SystemAdminSideBar from "./SystemAdminSideBar";
import ClientSelectOrgComponent from "./ClientSelectOrgComponent";

const CommonSystemAdminLayout = ({ navItems, layout = "client" }) => {
  return (
    <ScrollableGrid scrollHeight={{ xs: "95vh", md: "90vh" }}>
      <Grid
        container
        spacing={2}
        display={{ xs: "flex", md: "block" }}
        flexDirection={{ xs: "column", md: "none" }}
        justifyContent={{ xs: "center", md: "none" }}
        alignItems={{ xs: "center", md: "none" }}
        sx={{
          height: { xs: "none", md: "90vh" },
        }}
        mt={layout === "system" && { xs: 10, md: 0 }}
      >
        {layout === "client" ? <ClientSelectOrgComponent /> : null}
        <Grid
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          justifyContent={{ xs: "center" }}
          alignItems={{ xs: "center", md: "flex-start" }}
          sx={{ marginBottom: "2em" }}
          gap={2}
          width="100%"
          height="100%"
        >
          <Grid item md={2} xs={12} width="100%" height="100%">
            <PanelCard sx={{ display: "flex", justifyContent: "center" }}>
              <Box sx={{ height: "72vh", overflow: "scroll" }}>
                <SystemAdminSideBar
                  key={`${layout}-settings`}
                  items={navItems}
                />{" "}
              </Box>
            </PanelCard>
          </Grid>
          <Grid item md={10} xs={12} width="100%" height="100%">
            <PanelCard sx={{ height: "100%" }} contentSX={{ height: "100%" }}>
              <Outlet />
            </PanelCard>
          </Grid>
        </Grid>
      </Grid>
    </ScrollableGrid>
  );
};

export default CommonSystemAdminLayout;
