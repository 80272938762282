import * as Yup from "yup";
import moment from "moment";
import { removeTimeFromDate } from "./removeTimeFromDate";

export const customValidationForDate = (value) => {
  if (value) {
    value = removeTimeFromDate(value, "moment");

    // Ensure the value is a valid ISO date
    if (!moment(value, moment.ISO_8601, true).isValid()) {
      return false;
    }

    // Check if date is between 1930 and current date + 10 years
    const date = moment(value);
    const minDate = moment("1930-01-01", "YYYY-MM-DD");
    const maxDate = moment().add(10, "years"); // current date + 10 years

    if (!date.isBetween(minDate, maxDate, "day", "[]")) {
      return false;
    }
  }

  return true;
};
