import { Close, Edit, Save } from "@mui/icons-material";
import { Box, Grid, Select, MenuItem } from "@mui/material";
import CustomTooltip from "../../../../common/CustomTooltip.jsx";
import { useMemo, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateAssigneeForCandidate,
  getTeamsByOrgId,
  updateTeamsByTeamId,
} from "../../../../../store/actions/operationActions.js";
import { updateStateWith } from "../../OperationsCaseManagement/components/innerComponents/CMCheckStatus/utils/updateStateWith.js";
import { setToastNotification } from "../../../../../store/actions/toastNotificationActions.js";
import { ERROR } from "../../../../../store/constant.js";
import { checkActionPermission } from "../../../../../utils/CheckPageAccess.js";
import permissionKey from "../../../../constants/permissionKey.js";
import { getCurrentFileNameAndFunction } from "../../../../../utils/getCurrentFileNameAndFunction.js";
import CircularLoader from "./../../../../../common/CircularLoader";

const UpdateTableRowValue = ({
  data,
  updateValue,
  setUpdateValue,
  assigneeLists = [],
  update_id,
  loadingTableCellState,
  fetchCandidatesOnSave = () => {},
  tableLoading
}) => {
  const [selectedValue, setSelectedValue] = useState("");
  const dispatch = useDispatch();

  const { permissions } = useSelector((state) => state.authorization);
  const { teamsByOrgIdData } = useSelector((state) => state.operations);

  useEffect(() => {
    if (update_id === "assignee" && !data.assignee && !data.assigneeName) {
      // updateStateWith(setUpdateValue, data?.candidatesCasesId, false);
      setUpdateValue({ [data?.candidatesCasesId]: false });
    }
  }, [data]);

  let listData = useMemo(() => {
    if (update_id === "assignee") {
      return assigneeLists?.map((curr) => ({
        id: curr?.assignee,
        name: curr?.assigneeName,
      }));
    } else if (update_id === "team") {
      return Object.keys(teamsByOrgIdData)?.length
        ? (teamsByOrgIdData[data?.hrOrganizationId] ?? [])?.map((curr) => ({
            id: curr?.hrTeamsId,
            name: curr?.hrTeamName,
          }))
        : [];
    }
  }, [data, update_id, assigneeLists, teamsByOrgIdData]);

  const checkUpdatePermission = () => {
    let logDetails = getCurrentFileNameAndFunction(
      import.meta.url,
      "checkUpdatePermission"
    );

    let key =
      update_id === "assignee" ? "opsAssigneUser" : "updateCandidateTeam";

    let permissionsArr =
      update_id === "assignee"
        ? permissions
        : {
            actionsPermissions: data?.permissions,
          };

    let User = JSON.parse(
      JSON.parse(localStorage.getItem("first_login"))?.CheckMinistryUser
    );

    if (
      (User?.roleId === 2 &&
        !checkActionPermission(permissionKey[key], permissionsArr)) ||
      (User?.roleId === 4 && User?.subRoleId !== 6)
    ) {
      dispatch(
        setToastNotification(
          ERROR,
          "You dont have permission to update",
          logDetails
        )
      );
      return false;
    }
    return true;
  };

  const handleEdit = () => {
    if (!checkUpdatePermission()) return;

    setSelectedValue(
      update_id === "assignee"
        ? data?.assignee
        : update_id === "team" && data?.hrTeamsId
    );

    // updateStateWith(setUpdateValue, data?.candidatesCasesId, true);
    setUpdateValue({ [data?.candidatesCasesId]: true });

    if (update_id === "team" && !teamsByOrgIdData[data?.hrOrganizationId]) {
      let logDetails = getCurrentFileNameAndFunction(
        import.meta.url,
        "handleEdit"
      );

      dispatch(
        getTeamsByOrgId(
          data?.hrOrganizationId,
          data?.candidatesCasesId,
          logDetails
        )
      );
    }
  };

  const handleSave = () => {
    if (!checkUpdatePermission()) return;

    let logDetails = getCurrentFileNameAndFunction(
      import.meta.url,
      "handleSave"
    );

    if (selectedValue) {
      if (update_id === "assignee") {
        dispatch(
          updateAssigneeForCandidate(
            { assignee: selectedValue },
            data?.candidatesCasesId,
            () => setSelectedValue(""),
            fetchCandidatesOnSave,
            logDetails
          )
        );
      } else if (update_id === "team") {
        dispatch(
          updateTeamsByTeamId(
            data?.hrOrganizationId,
            data?.candidatesCasesId,
            data?.hrTeamsId,
            {
              newTeamId: selectedValue,
            },
            () => setSelectedValue(""),
            fetchCandidatesOnSave,
            logDetails
          )
        );
      }

      // updateStateWith(setUpdateValue, data?.candidatesCasesId, false);
      setUpdateValue({ [data?.candidatesCasesId]: false });
    } else {
      dispatch(
        setToastNotification(
          ERROR,
          `Please select a valid ${
            update_id === "assignee"
              ? "assignee"
              : update_id === "team" && "team"
          } for ${data?.caseNumber}`,
          logDetails
        )
      );
    }
  };

  const handleClose = () => {
    if (!checkUpdatePermission()) return;

    setSelectedValue("");
    // updateStateWith(setUpdateValue, data?.candidatesCasesId, false);
    setUpdateValue({ [data?.candidatesCasesId]: false });
  };

  return (
    <Grid
      item
      xs={12}
      display="flex"
      justifyContent="space-around"
      alignItems="center"
    >
      {updateValue[data?.candidatesCasesId] ? (
        <Grid item xs={9} width="100%" overflow="auto">
          {update_id === "assignee" ||
          teamsByOrgIdData[data?.hrOrganizationId]?.length ? (
            <Select
              displayEmpty
              value={selectedValue || ""}
              size="small"
              variant="outlined"
              onChange={(event) => setSelectedValue(event.target.value)}
              disabled={
                update_id === "assignee" &&
                !checkActionPermission(
                  permissionKey.opsAssigneUser,
                  permissions
                )
              }
              sx={{
                transform: "scale(0.8)",
                height: "35px",
                "& .MuiSelect-select": {
                  display: "flex",
                  alignItems: "center",
                  fontSize: "12px",
                },
              }}
            >
              <MenuItem value="" sx={{ fontSize: "12px" }}>
                Select{" "}
                {update_id === "assignee"
                  ? "Assignee"
                  : update_id === "team" && "Team"}
              </MenuItem>
              {listData?.map((option, index) => (
                <MenuItem
                  key={index}
                  value={option.id}
                  sx={{ fontSize: "12px" }}
                >
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          ) : update_id === "team" &&
            loadingTableCellState?.candidatesCasesId ===
              data?.candidatesCasesId &&
            loadingTableCellState?.loading ? (
            <CircularLoader size={10} height="5vh" />
          ) : null}
        </Grid>
      ) : (
        <Grid item xs={9} width="100%" overflow="auto">
          {(loadingTableCellState?.candidatesCasesId ===
            data?.candidatesCasesId &&
            loadingTableCellState?.loading) ||
          tableLoading ? (
            <CircularLoader size={10} height="5vh" />
          ) : (
            <>
              {(update_id === "assignee"
                ? data?.assigneeName
                : update_id === "team" && data?.hrTeamName) ||
                "Not Assigned Yet"}
            </>
          )}
        </Grid>
      )}
      {updateValue[data?.candidatesCasesId] ? (
        <Grid
          item
          xs={3}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={6}>
            <CustomTooltip title="Save" placement="top">
              <Box>
                <Save
                  onClick={handleSave}
                  sx={{
                    margin: "5px 0 0",
                    cursor: "pointer",
                    color: (theme) => theme.palette.success.main,
                  }}
                />
              </Box>
            </CustomTooltip>
          </Grid>
          <Grid item xs={6}>
            <CustomTooltip title="Close" placement="top">
              <Box>
                <Close
                  onClick={handleClose}
                  sx={{
                    margin: "5px 0 0",
                    cursor: "pointer",
                    color: (theme) => theme.palette.error.light,
                  }}
                />
              </Box>
            </CustomTooltip>
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={3} display="flex" justifyContent="center">
          <CustomTooltip title="Update" placement="top">
            <Box>
              <Edit
                onClick={handleEdit}
                sx={{
                  cursor: "pointer",
                  color: (theme) =>
                    loadingTableCellState?.loading || tableLoading
                      ? theme.palette.grey[400]
                      : theme.palette.warning.dark,
                  pointerEvents : loadingTableCellState?.loading || tableLoading ? 'none': 'all'
                }}
              />
            </Box>
          </CustomTooltip>
        </Grid>
      )}
    </Grid>
  );
};

export default UpdateTableRowValue;
