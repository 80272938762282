import { GridToolbar, GridToolbarContainer } from "@mui/x-data-grid";

function CustomToolBarForDataGridTable() {
  return (
    <GridToolbarContainer>
      <GridToolbar printOptions={{ disableToolbarButton: true }} />
    </GridToolbarContainer>
  );
}

export default CustomToolBarForDataGridTable;
