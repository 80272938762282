export const newAddress = () => {
  return {
    toDate: "",
    streetAddressLine1: "",
    streetAddressLine2: "",
    cityTownDistrict: "",
    stateProvince: "",
    zipPostalAreaCode: "",
    countryId: null,
    fromDate: "",
    isExpanded: true,
    selectedTab:true
  };
};
