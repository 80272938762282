import moment from "moment";

export const removeTimeFromDate = (value, useLogic = "Date") => {
  if (value) {
    if (useLogic === "Date") {
      const dateObj = new Date(value);

      const month = dateObj.getMonth() + 1;
      const day = dateObj.getDate();
      const year = dateObj.getFullYear();
      let formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}`; //yyyy-mm-dd

      return formattedDate;
    } else if (useLogic === "moment") {
      return moment
        .parseZone(value, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)")
        .format("YYYY-MM-DD");
    }
  }

  return value;
};
