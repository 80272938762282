export const checksIdsWithResearchCountries = [
  {
    checksId: 5,
    checkName: "Right to Work Check",
  },
  {
    checksId: 6,
    checkName: "Civil Litigation Check",
  },
  {
    checksId: 7,
    checkName: "Credit Check",
  },
  {
    checksId: 9,
    checkName: "Bankruptcy Check",
  },
  {
    checksId: 17,
    checkName: "Directorship Check",
  },
  {
    checksId: 18,
    checkName: "Financial Regulatory Check",
  },
  {
    checksId: 20,
    checkName: "Adverse Media Check (Local Language only)",
  },
  {
    checksId: 22,
    checkName: "Criminal Check",
  },
];
