import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import styled from "@emotion/styled";
import _ from "lodash";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { getTimestampFromString } from "../../../../utils/DateTimeHelper";

const HrModalBox = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  min-height: 55vh;
  min-width: ${({ smallScreen }) => (smallScreen ? "100vw" : "60vw")};
  overflow-y: scroll;
`;

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

let columns = [
  {
    headerName: "VERIFICATION PROCESS NAME",
    field: "verificationProcessName",
    minWidth: 250,
    flex: 1,
    renderCell: (params) => (
      <a href={params.row.reportCdnPath} target="_blank">
        {params.row.verificationProcessName}
      </a>
    ),
  },
  {
    headerName: "CREATED AT",
    field: "createdAt",
    width: 250,
    valueFormatter: ({ value }) => getTimestampFromString(value),
  },
];

const PdfReportModal = ({ open, handleClose, latestReport }) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      {latestReport?.length ? (
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            sx: {
              maxWidth: "none",
              maxHeight: "95vh",
            },
          }}
        >
          <HrModalBox smallScreen={smallScreen}>
            <StyledDialogContent>
              <div
                style={{
                  height: 450,
                }}
              >
                <DataGrid
                  initialState={{
                    sorting: {
                      sortModel: [{ field: "createdAt", sort: "desc" }],
                    },
                  }}
                  // components={{ Toolbar: GridToolbar }}
                  rows={latestReport}
                  columns={columns}
                  // pageSize={50}
                  pageSize={8}
                  rowsPerPageOptions={[50]}
                  density="standard"
                  getRowId={(row) => row.createdAt}
                  xs={{ width: "100vw" }}
                  hideFooter
                  hideFooterPagination
                  disableColumnFilter
                  disableColumnSelector
                />
              </div>
            </StyledDialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="error"
                onClick={handleClose}
                disableElevation
              >
                Close
              </Button>
            </DialogActions>
          </HrModalBox>
        </Dialog>
      ) : null}
    </>
  );
};

export default PdfReportModal;
