import * as Yup from "yup";

export const addInternalUsersSchema = Yup.object().shape({
  userName: Yup.string()
    .required("Field is required!")
    .typeError("Field is required!")
    .max(200, "Should not exceed 200 characters"),
  userEmail: Yup.string()
    .email("Please provide a valid email address")
    .required("Field is required!")
    .typeError("Field is required!")
    .max(200, "Should not exceed 200 characters"),
  role: Yup.number()
    .required("Field is required!")
    .typeError("Field is required!"),
  subRole: Yup.number()
    .required("Field is required!")
    .typeError("Field is required!"),
});
