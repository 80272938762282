import { useEffect, useMemo } from "react";
import ErrorPageTemplate from "./ErrorPageTemplate";
import { deleteToken } from "../store/actions/authorizationAction";
import { Typography } from "@mui/material";
import OrgPendingStatusImage from "../assets/images/org-pending-status.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const OrgPendingStatusErrorPage = () => {
  const navigate = useNavigate();

  const loggedInUser = useSelector((state) => state.authorization);

  let orgStatus = useMemo(
    () => loggedInUser?.currentUser?.hrOrganization?.hrOrganizationStatus,
    [loggedInUser]
  );

  useEffect(() => {
    //logging out user automatically after 1 min if status is REJECTED
    if (orgStatus === "REJECTED") {
      let timeout = setTimeout(() => {
        deleteToken();
        navigate("/login");
      }, 60000);

      return () => timeout && clearTimeout(timeout);
    } else if (orgStatus === "APPROVED") {
      navigate("/");
    }
  }, [orgStatus]);

  return (
    <ErrorPageTemplate
      bodyText={
        <Typography
          fontWeight={600}
          fontSize={20}
          textAlign="center"
          color="#fff"
          mb={2}
        >
          {orgStatus === "REJECTED" ? (
            <>
              Your organization has been rejected! Please contact the
              CheckMinistry support for further assistance.
              <br />
              <br />
              We appreciate your patience!
            </>
          ) : (
            <>
              Thank You for creating your Account with CheckMinistry!
              <br />
              <br />
              Currently our team is reviewing your registration, your account
              will be approved shortly and we will notify you by email!
              <br /> <br />
              We appreciate your patience!
            </>
          )}
        </Typography>
      }
      errorImage={OrgPendingStatusImage}
    />
  );
};

export default OrgPendingStatusErrorPage;
