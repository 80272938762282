export const createOrgInitialValues = {
  userName: "",
  loginEmail: "",
  phoneNumber: "",
  roleId: 2,
  industriesId: [],
  hrOrganizationName: "",
  hrTeamName: "Default Team",
  hrOrganizationDisplayName: "ABC enterprise",
  isVendor:false
};
