import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Alert,
  Grid,
  MenuItem,
  Select,
  Typography,
  useTheme,
  styled,
  Box,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import EastIcon from "@mui/icons-material/East";
import { transferCandidatesToNewTeam } from "../../store/actions/hrActions";
import { getLoggedInUserHrOrganizationId } from "../../utils/UserHelper";
import { getCurrentFileNameAndFunction } from "../../utils/getCurrentFileNameAndFunction";

const HrModalBox = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 10px;
  min-width: 500px;
  min-height: 200px;
`;

const HrAlert = styled(Alert)(({ theme }) => ({
  backgroundColor: theme.palette.error.light,
  color: theme.palette.error.main,
  fontWeight: 500,
  "& .MuiAlert-icon": {
    color: theme.palette.error.main,
  },
}));

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TransferCandidateToNewTeamModal = ({
  open,
  handleClose,
  selectedTeam,
  organizationTeamsData,
}) => {
  const dispatch = useDispatch();
  const [disableBtn, setDisableBtn] = useState(false);
  const [selectedTeamId, setSelectedTeamId] = useState("");

  const theme = useTheme();

  const handleSubmit = () => {
    let logDetails = getCurrentFileNameAndFunction(
      import.meta.url,
      "handleSubmit"
    );

    dispatch(
      transferCandidatesToNewTeam(
        getLoggedInUserHrOrganizationId(),
        selectedTeam?.hrTeamsId,
        { newTeamId: selectedTeamId },
        setDisableBtn,
        handleClose,
        logDetails
      )
    );
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <HrModalBox>
        <DialogTitle>
          <Typography variant="h2" style={{ fontWeight: 550 }}>
            Transfer Candidates to New Team
          </Typography>
        </DialogTitle>
        <StyledDialogContent>
          {organizationTeamsData?.length > 1 ? (
            <Box display="flex" alignItems="center" gap={2}>
              <Box maxWidth="150px" overflow="auto" flexGrow={1}>
                {selectedTeam?.hrTeamName}
              </Box>
              <Box>
                <EastIcon />
              </Box>
              <Box flexGrow={1}>
                <Select
                  defaultValue={selectedTeamId}
                  onChange={(c, key) => setSelectedTeamId(key.props.value)}
                  sx={{ width: "100%" }}
                >
                  {organizationTeamsData
                    ?.filter(
                      (curr) => curr?.hrTeamsId !== selectedTeam?.hrTeamsId
                    )
                    ?.map(({ hrTeamsId, hrTeamName }, _) => (
                      <MenuItem value={hrTeamsId} key={hrTeamsId}>
                        {hrTeamName}
                      </MenuItem>
                    ))}
                </Select>
              </Box>
            </Box>
          ) : (
            <Typography fontWeight={550} textAlign="center">
              Please Add One More Team to Transfer the Cases
            </Typography>
          )}
        </StyledDialogContent>
        <DialogActions sx={{ padding: "8px 24px 0" }}>
          <Button
            variant="contained"
            color="error"
            onClick={handleClose}
            disableElevation
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            style={{
              backgroundColor:
                disableBtn || !selectedTeamId
                  ? theme.palette.grey[200]
                  : theme.palette.primary.main,
              color: theme.palette.background.paper,
              paddingLeft: "30px",
              paddingRight: "30px",
              marginLeft: "20px",
              border: `1px solid ${
                disableBtn || !selectedTeamId
                  ? theme.palette.grey[200]
                  : theme.palette.primary.main
              }`,
            }}
            onClick={handleSubmit}
            disableElevation
            disabled={disableBtn || !selectedTeamId}
            loading={disableBtn}
          >
            Transfer
          </LoadingButton>
        </DialogActions>
      </HrModalBox>
    </Dialog>
  );
};

export default TransferCandidateToNewTeamModal;
