import { useNavigate, useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CandidatesTable from "./CandidatesTable";
import { getLoggedInUserHrOrganizationId } from "../../utils/UserHelper";
import TeamRangeSelection from "../common/TeamRangeSelection";
import { useDispatch, useSelector } from "react-redux";
import {
  checkActionPermission,
  checkPagePermission,
} from "../../utils/CheckPageAccess";
import permissionKey from "../constants/permissionKey";
import ScrollableGrid from "../../common/ScrollableGrid";
import PanelCard from "../../common/cards/PanelCard";
import AddMultipleCandidatesModal from "./CandidatesTable/Modals/AddMultipleCandidatesModal";
import { getFailedToRegisterCandidates } from "../../store/actions/hrActions";
import { hrCandidatesByOrgId } from "../../store/actions/hrActions";
import {
  GET_HR_CANDIDATES,
  GET_TEAMS_BY_ORG_ID_DATA,
} from "../../store/actions/actionTypes";
import { setToastNotification } from "../../store/actions/toastNotificationActions";
import { ERROR } from "../../store/constant";
import moment from "moment";
import { ClearOutlined, SearchOutlined } from "@mui/icons-material";
import { debounce } from "lodash";
import { getCurrentFileNameAndFunction } from "../../utils/getCurrentFileNameAndFunction";
import { rangeSelectors } from "../constants/filterData";

const CandidatesPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterCandidates, setFilteredCandidates] = useState("");

  const loggedInUser = useSelector((state) => state.authorization);

  const [addMultipleCandidatesModal, setAddMultipleCandidatesModal] =
    useState(false);

  useEffect(() => {
    return () => {
      //to reset teams lists by org id state for updating team name in table
      dispatch({
        type: GET_TEAMS_BY_ORG_ID_DATA,
        payload: "reset",
      });

      //to reset candidates lists
      dispatch({
        type: GET_HR_CANDIDATES,
        payload: {
          candidatesLists: [],
          totalCandidateCaseCount: 0,
          allCandidateDataForReport: [],
        },
      });
    };
  }, []);

  const hrCandidateCreateAccess = useMemo(
    () =>
      checkActionPermission(
        permissionKey.hrInviteCandidate,
        loggedInUser.permissions
      ) &&
      checkPagePermission("/hr/candidates/create", loggedInUser.permissions),
    [loggedInUser.permissions]
  );

  useEffect(() => {
    const filterParams = {
      hrTeamId: searchParams.get("hrTeamId") || "All",
      fromDate:
        searchParams.get("fromDate") || rangeSelectors[0]?.dates?.fromDate,
      toDate: searchParams.get("toDate") || rangeSelectors[0]?.dates?.toDate,
      searchText: searchParams.get("searchText") || "",
      periodCode: searchParams.get("periodCode") || rangeSelectors[0]?.label,
      rangeValue: searchParams.get("rangeValue") || 0,
      verificationProcessId: searchParams.get("verificationProcessId") || "2,3",
      verificationResultId: searchParams.get("verificationResultId") || "3",
      pageNumber: searchParams.get("pageNumber") || 1,
    };

    setFilteredCandidates(filterParams?.searchText);

    //setting params to url
    setSearchParams((prevParams) => {
      return new URLSearchParams({
        ...Object.fromEntries(prevParams.entries()),
        ...filterParams,
      });
    });

    onFetchCandidates(filterParams);
  }, [
    searchParams.get("hrTeamId"),
    searchParams.get("fromDate"),
    searchParams.get("toDate"),
    searchParams.get("searchText"),
    searchParams.get("periodCode"),
    searchParams.get("rangeValue"),
    searchParams.get("verificationProcessId"),
    searchParams.get("verificationResultId"),
    searchParams.get("pageNumber"),
  ]);

  const onFilterChange = (finalFilters) => {
    let logDetails = getCurrentFileNameAndFunction(
      import.meta.url,
      "onFilterChange"
    );

    dispatch(
      hrCandidatesByOrgId(
        getLoggedInUserHrOrganizationId(),
        finalFilters,
        logDetails
      )
    );
  };

  const debouncedSearch = useCallback(debounce(onFilterChange, 500), [
    dispatch,
  ]);

  const onFetchCandidates = useCallback(
    (finalFilters) => {
      delete finalFilters?.rangeValue;

      const from = moment(finalFilters?.fromDate);
      const to = moment(finalFilters?.toDate);
      let logDetails = getCurrentFileNameAndFunction(
        import.meta.url,
        "onFetchCandidates"
      );

      // Compare the dates
      if (from >= to) {
        dispatch(
          setToastNotification(
            ERROR,
            "From date should be smaller than To date",
            logDetails
          )
        );
      } else {
        debouncedSearch(finalFilters);
      }
    },
    [searchParams]
  );

  const handleModalOpen = () => {
    setAddMultipleCandidatesModal(true);

    let logDetails = getCurrentFileNameAndFunction(
      import.meta.url,
      "handleModalOpen"
    );

    dispatch(getFailedToRegisterCandidates(logDetails));
  };

  const handleOnChangeSearch = (val) => {
    setSearchParams((prevParams) => {
      return new URLSearchParams({
        ...Object.fromEntries(prevParams.entries()),
        searchText: val,
      });
    });

    setFilteredCandidates(val);
  };

  return (
    <ScrollableGrid>
      <Grid
        container
        spacing={2}
        sx={{ height: "90vh" }}
        mt={{ xs: 8, md: -2 }}
      >
        <Grid item md={2} sm={12} xs={12}>
          <PanelCard>
            <TeamRangeSelection />
          </PanelCard>
        </Grid>
        <Grid item md={10} sm={12} xs={12}>
          <PanelCard>
            <Grid
              container
              alignItems="center"
              spacing={2}
              marginBottom="1rem"
              direction={{ xs: "column", sm: "row" }}
            >
              <Grid item xs={12} sm={hrCandidateCreateAccess ? 3 : 9}>
                <Typography variant="h4" sx={{ marginBottom: "10px" }}>
                  Candidates
                </Typography>
              </Grid>
              {hrCandidateCreateAccess && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  display="flex"
                  justifyContent={{ xs: "space-between", sm: "flex-end" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                  alignItems={{ xs: "center", sm: "none" }}
                >
                  <Button
                    variant="contained"
                    size="small"
                    disableElevation
                    startIcon={<AddIcon />}
                    onClick={handleModalOpen}
                  >
                    Add Multiple Candidates
                  </Button>

                  <Button
                    sx={{
                      margin: { xs: "10px 0 0 0", sm: "0 0 0 10px" },
                    }}
                    variant="contained"
                    size="small"
                    disableElevation
                    startIcon={<AddIcon />}
                    onClick={() => navigate("/hr/candidates/create")}
                  >
                    Create Candidate
                  </Button>
                </Grid>
              )}
              <Grid ml={1} item xs={12} sm={2.9}>
                <TextField
                  value={filterCandidates}
                  placeholder="Search..."
                  type="text"
                  fullWidth={true}
                  size="small"
                  variant="outlined"
                  onChange={(e) => handleOnChangeSearch(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlined style={{ marginLeft: "0.5rem" }} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <ClearOutlined
                          style={{ cursor: "pointer" }}
                          onClick={() => handleOnChangeSearch("")}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <CandidatesTable onFetchCandidates={onFetchCandidates} />
          </PanelCard>
        </Grid>
      </Grid>
      {addMultipleCandidatesModal && (
        <AddMultipleCandidatesModal
          open={addMultipleCandidatesModal}
          handleClose={() => setAddMultipleCandidatesModal(false)}
          onFetchCandidates={onFetchCandidates}
        />
      )}
    </ScrollableGrid>
  );
};

export { CandidatesPage };
