// extracted by mini-css-extract-plugin
export var accordionBorder = "#e0e0e0";
export var accordionHeaderBg = "#f2f5fe";
export var darkBackground = "#1a223f";
export var darkLevel1 = "#29314f";
export var darkLevel2 = "#212946";
export var darkLevel3 = "rgba(104,137,255,.1)";
export var darkPaper = "#6788ff";
export var darkPrimary200 = "#90caf9";
export var darkPrimary800 = "#1565c0";
export var darkPrimaryDark = "#1e88e5";
export var darkPrimaryLight = "#f2f5ff";
export var darkPrimaryMain = "#527afb";
export var darkSecondary200 = "#b39ddb";
export var darkSecondary800 = "#6200ea";
export var darkSecondaryDark = "#651fff";
export var darkSecondaryLight = "#d1c4e9";
export var darkSecondaryMain = "#7c4dff";
export var darkTextPrimary = "#5e5873";
export var darkTextSecondary = "#6e6b7b";
export var darkTextTernary = "#565261";
export var darkTextTitle = "#d7dcec";
export var errorDark = "#c62828";
export var errorLight = "#ef9a9a";
export var errorLighter = "#fdf4f4";
export var errorMain = "#f44336";
export var grey100 = "#f5f5f5";
export var grey150 = "#f3f3f3";
export var grey200 = "#dddce0";
export var grey300 = "#e0e0e0";
export var grey400 = "#d6d6d6";
export var grey50 = "#fafafa";
export var grey500 = "#9e9e9e";
export var grey600 = "#757575";
export var grey700 = "#616161";
export var grey900 = "#555262";
export var moderateViolet = "#673ab7";
export var orangeDark = "#d84315";
export var orangeLight = "#fbe9e7";
export var orangeMain = "#ffab91";
export var paper = "#fff";
export var primary100 = "#eaefff";
export var primary200 = "#90caf9";
export var primary800 = "#1565c0";
export var primaryDark = "#1e88e5";
export var primaryLight = "#f8f8f8";
export var primaryMain = "#527afb";
export var secondary200 = "#b39ddb";
export var secondary800 = "#4527a0";
export var secondaryDark = "#6788ff";
export var secondaryLight = "#f2f5ff";
export var secondaryMain = "#527afb";
export var success200 = "#69f0ae";
export var successDark = "#00e676";
export var successLight = "#b9f6ca";
export var successMain = "#00c853";
export var warningDark = "#ffc107";
export var warningLight = "#fff8e1";
export var warningMain = "#ffe57f";