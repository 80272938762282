export const newIdentity = () => {
  return {
    birthCountryId: null,
    primaryNationalityId: null,
    residenceCountryId: null,
    identityDocumentType: '',
    identityDocumentNumber: '',
    identityAttachments: [],
  };
};
