export const MultipleCandidateInitialValues = [
  {
    candidateName: "",
    candidatePhone: "",
    candidateEmail: "",
    hiringCountryName: "",
    hrTeamName: "",
    packageName: "",
    customFields:[]
  },
];
