import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import throttle from "lodash/throttle";
import { getLoggedInUserHrOrganizationId } from "../../../utils/UserHelper";
import { useDispatch } from "react-redux";
import { searchUsers } from "../../../store/actions/hrActions";
import { getCurrentFileNameAndFunction } from "../../../utils/getCurrentFileNameAndFunction";

export const AutoCompleteUser = (props) => {
  const [value, setValue] = React.useState(null);
  const [options, setOptions] = React.useState([]);
  const dispatch = useDispatch();

  const handleChange = (newValue) => {
    setOptions(newValue ? [newValue, ...options] : []);
    setValue(newValue);
    props.onChange(newValue);
  };

  const fetch = (inputValue) => {
    let logDetails = getCurrentFileNameAndFunction(import.meta.url, "fetch");

    dispatch(
      searchUsers(
        {
          q: inputValue,
          orgId: getLoggedInUserHrOrganizationId(),
        },
        props.currentUsers,
        setOptions,
        logDetails
      )
    );
  };

  const throttleFetch = React.useCallback(throttle(fetch, 500));

  const { errorTextField, setErrorTextField } = props;

  let validateEmail = new RegExp(
    /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
  );

  return (
    <Autocomplete
      value={value || ""}
      freeSolo
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          handleChange({
            loginEmail: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          handleChange({
            loginEmail: newValue.inputValue,
          });
        } else {
          handleChange(newValue);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setErrorTextField(false);

        if (!validateEmail.test(newInputValue)) {
          props.onChange(null);
        }

        if (newInputValue?.length > 2) {
          throttleFetch(newInputValue);
        } else {
          setOptions([]);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = options;

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some(
          (option) => inputValue === option.loginEmail
        );

        if (
          inputValue !== "" &&
          !isExisting &&
          validateEmail.test(inputValue)
        ) {
          filtered.push({
            inputValue,
            loginEmail: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
      // selectOnFocus
      // clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={options}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.loginEmail;
      }}
      renderOption={(props, option) => <li {...props}>{option.loginEmail}</li>}
      sx={{ width: 532 }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search by name or email address*"
          error={errorTextField}
        />
      )}
    />
  );
};
